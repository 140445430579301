<template>
  <v-row justify="center" v-if="dialog_log_item">
    <v-dialog v-model="dialog_log_flag" scrollable max-width="40%">
      <v-card>
        <v-card-title class="justify-center"
          >LOG - historico cambio de contraseñas</v-card-title
        >
        <v-divider></v-divider>
        <v-card-title>total de cambios: {{ totalRecords }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                no-data-text="nel maje"
                loading-text="Sin cambios"
                disable-pagination
                :options.sync="options"
                :hide-default-footer="true"
                :headers="headers"
                :items="items"
                class="elevation-1"
                :item-key="items.id"
                loading="true"
              >
                <template slot="item.createdAt" slot-scope="props">
                  <span> {{ getDate(props.item.createdAt) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            class="float-right"
            @click="$emit('fndialogEdit', false)"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="550px">
      <v-card>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" class="d-inline-flex justify-center">
              <v-card-title>
                ¿Está seguro de querer desvincular el dispositivo?
              </v-card-title>
            </v-col>

            <v-col class="d-inline-flex justify-left">
              <v-btn color="red" dark @click="dialog2 = !dialog2">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      dialogm1: '',
      dialog: false,
      dialog2: false,
      /** datatable variables */
      options: {},
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      items: [],
      headers: [
        { text: 'Quien actualizo', value: 'alias_usuario_administrativo' },
        { text: 'Fecha de actualización', value: 'createdAt' },
      ],
    };
  },
  props: {
    dialog_log_item: {
      type: Object,
    },
    dialog_log_flag: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog_log_item(newValue) {
      if (newValue !== null) {
        this.fnFindLogs();
      }
    },
  },
  methods: {
    ...mapActions('logHistoricoContrasenas', {
      logFind: 'find',
    }),
    getParams() {
      const params = {};
      params['alias_usuario_cambio_contrasena'] = this.dialog_log_item.alias;
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? 1 : -1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      }

      return params;
    },
    fnFindLogs() {
      this.logFind({ query: this.getParams() }).then((result) => {
        console.log(result);
        this.items = result.data;
        this.totalRecords = result.total;
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
      });
    },
    getDate(date) {
      return date ? moment(date).format('DD MMMM YYYY hh:mm a') : '';
    },
    fn_checkDesvincularDispositivo() {
      if (this.dialog_log_item) {
        this.dialog2 = true;
      }
    },
  },
  mounted() {},
};
</script>
