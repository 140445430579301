<template>
  <v-card class="mb-12 card-modulos" color="" height="100%" elevation="0">
    <v-card-text class="card-text-modulos">
      <v-row>
        <v-col
          ><v-text-field
            v-model="search"
            label="Código / Nombre de supervisora"
            class="mx-4"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field
        ></v-col>
        <v-col cols="12">
          <v-data-table
            :loading="tabla_loading"
            disable-pagination
            :options.sync="options"
            :hide-default-footer="true"
            v-model="selected"
            :headers="headers"
            :items="tbl_supervisoras"
            :single-select="singleSelect"
            item-key="id"
            item-value="id"
            show-select
            class="elevation-1"
          >
            <template v-slot:top> </template>
          </v-data-table>
        </v-col>
        <v-col>
          <v-pagination
            class="float-right"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-row> </v-card-text
  ></v-card>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'usuarioGestionSupervisoras',
  data() {
    return {
      tabla_loading: false,
      /** */
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      /** */
      search: '',
      //
      selected: [
        // {
        //   alias: 'Ice cream sandwich',
        //   nombre: 'Usuario 237',
        //   totSupervisoras: '9 supervisoras',
        //   createdAt: '2021-09-01T21:39:21.748Z',
        // },
      ],
      singleSelect: false,
      tbl_supervisoras: [],
      headers: [
        { text: 'Nombre', value: 'nombre_completo' },
        { text: 'Usuario', value: 'alias' },
        {
          text: 'Sector',
          align: 'start',
          sortable: false,
          value: 'codigo',
        },

        { text: 'División', value: 'division' },
        { text: 'Región', value: 'region' },
        { text: 'Agente de comercio', value: 'tipo' },
      ],
    };
  },
  props: {
    prop_selected_ids: {
      type: Array,
    },
  },
  watch: {
    search() {
      this.tabla_loading = true;
      this.page = 1;
      this.fnFindUsuarios();
    },
    selected(newValue) {
      this.$emit('supervisorasUpdate', newValue);
    },
    prop_selected_ids(newValue) {
      // true pagination
      this.findUsuarios({ query: { id: { $in: newValue }, $limit: 600 } })
        .then((result) => {
          this.selected = result.data;
        })
        .catch(() => {
          console.log('No se ha podido mostrar los usuarios');
        });
    },
  },
  methods: {
    ...mapActions('Usuarios', {
      getUsuarios: 'get',
      findUsuarios: 'find',
      crearUsuario: 'create',
    }),
    handlePageChange(value) {
      this.page = value;
      this.fnFindUsuarios();
    },
    getParams() {
      const params = {};
      params['sesion_rol'] = 2;

      // params['$or'] = [
      //   {
      //     tipo: 'Supervisoras',
      //   },
      //   {
      //     tipo: 'Gerente de Division',
      //   },
      //   {
      //     tipo: 'Gerente de Region',
      //   },
      // ];

      params['$or'] = [
        {
          alias: { $like: '%' + this.search + '%' },
        },
        {
          nombre_completo: { $like: '%' + this.search + '%' },
        },
        {
          region: { $like: '%' + this.search + '%' },
        },
      ];

      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;

      return params;
    },
    fnFindUsuarios() {
      this.findUsuarios({ query: this.getParams() })
        .then((result) => {
          this.tbl_supervisoras = result.data;
          this.totalPages = Math.ceil(
            parseInt(result.total) / parseInt(this.pageSize)
          );

          this.tabla_loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.tbl_supervisoras = [];
          this.totalPages = 0;

          this.tabla_loading = false;
        });
    },
  },
  mounted() {
    this.fnFindUsuarios();
    /**
     * escuchar al crear para borrar los selecionados
     */
    const { Usuarios } = this.$FeathersVuex.api;
    Usuarios.on('created', () => {
      this.selected = [];
    });
  },
};
</script>
<style>
#app
  > div.v-dialog__content.v-dialog__content--active
  > div
  > div
  > div.v-card__text
  > div
  > div:nth-child(1)
  > div
  > div.v-stepper__items
  > div:nth-child(3)
  > div
  > div
  > div
  > div.v-data-table.elevation-1.v-data-table--has-bottom.theme--light
  > div.v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1)
  > div {
  display: none !important;
}
</style>
