<template>
  <v-card class="mx-auto" max-width="">
    <v-list shaped>
      <v-list-item-group v-model="selected" multiple>
        <template v-for="(item, i) in items">
          <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item"
            active-class=" text--accent-4"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.titulo"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'usuarioGestionModuloss',
  data: () => ({
    items: [],
    selected: [],
  }),
  props: {
    listadoDeModulos_ModulosRestringidos: {
      type: Boolean,
    },
    prop_selected_ids: {
      type: Array,
    },
  },
  watch: {
    selected(newValue) {
      this.$emit('modulosUpdate', newValue);
    },
    prop_selected_ids(newValue) {
      this.findModulos({ query: { id: { $in: newValue }, $limit: 600 } }).then(
        (result) => {
          this.selected = result.data;
        }
      );
    },
  },
  methods: {
    ...mapActions('Modulos', {
      getModulos: 'get',
      findModulos: 'find',
      crearModulo: 'create',
    }),
  },
  mounted() {
    const params = {};

    if (this.listadoDeModulos_ModulosRestringidos == true) {
      params['id'] = { $nin: [6, 7] };
    }
    this.findModulos({ query: params }).then((result) => {
      this.items = result.data;
    });
    /**
     * escuchar al crear para borrar los selecionados
     */
    const { Usuarios } = this.$FeathersVuex.api;
    Usuarios.on('created', () => {
      this.selected = [];
    });
  },
};
</script>
